import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Image, Row, Col } from "react-bootstrap"

import Image01 from "../../../images/koenigsschiessen/2012/01.jpg"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Gallery from "../../../components/gallery"

const Koenigsschiessen2012 = props => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativePath: { regex: "/koenigsschiessen/2012/gallery_.*.jpg/" }
        }
      ) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fixed(width: 480) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Königsschießen 2012" lang="de" />
      <Container>
        <h1 className="h3">Königsschießen 2012</h1>
        <hr className="featurette-divider" />
        <p>
          Das diesjährige Sommerfest fand am 4. August mit dem 17.
          Königsschießen statt. Wunderschönes Wetter begleitete auch diesmal den
          Tag. Es fanden sich wieder viele Schützenschwestern und -brüder im
          Schützenhaus Gambach zu dieser traditionellen Veranstaltung ein. Der
          Vorsitzende Friedhelm Schwenz konnte neben den erschienenen
          Mitgliedern auch einige Gäste begrüßen und bedankte sich bei Eberhard
          und Ulrike Beppler-Alt sowie Monika Hölzemann für die Organisation
          sowie den Spendern der Kuchen und Salaten.
        </p>
        <p>
          Gegen 14.30 Uhr traten 23 Schützinnen und Schützen sowie die Jüngsten
          des Vereins zum diesjährigen Königsschießen an. Mit dem Luftgewehr
          wurde aus einer Entfernung von 10m auf die hölzernen, von Michael
          Rheinfrank angefertigten Königsadler geschossen. Nachdem das »Zepter«
          und der »Apfel« des Adlers gefallen und damit die »Ritter« und
          »Edelfräulein« ermittelt waren, ließ man sich die verschiedenen
          selbstgebackenen Kuchen zum Kaffee schmecken. Anschließend wurde der
          Wettkampf fortgesetzt, um die neuen »Majestäten« zu ermitteln. Nach
          knapp zweistündigem Wettkampf standen die Würdenträger für das Jahr
          2012 fest. In diesem Jahr wurde Denise Döring Schützenkönigin, Lothar
          Döring errang, wie bereits im letzten Jahr, auch dieses Jahr den Titel
          des Schützenkönigs ─ sie erhielten die silbernen Königsketten mit dem
          aktuellen Kettenanhängsel. 1. Edelfräulein wurde Katharina Beppler-Alt
          und 1. Ritter Michael Rheinfrank; als 2. Edelfräulein sowie 2. Ritter
          vervollständigten Monika Hölzemann und Harald Fink die Königsfamilie.
          Nach dem Wettkampf stärkten sich die Schützen mit leckeren Salaten und
          »Krustenbraten«, die wie immer von Rigo Schubach hervorragend
          zubereitet wurden. Die Schützenfamilie saß anschließend in gemütlicher
          Runde und ließ das Sommerfest erst in den späten Abendstunden
          ausklingen.
        </p>

        <p>
          Nach dem Wettkampf präsentierte sich die neue Gambacher
          »Königsfamilie«: Michael Rheinfrank (2. Ritter) Nico Ohlemutz
          (Jugendritter), Katharina Beppler-Alt (1. Edelfräulein), Denise Döring
          (Schützenkönigin), Lothar Döring (Schützenkönig), Harald Fink (1.
          Ritter) und Monika Hölzemann (2. Edelfräulein) – Foto: fs
        </p>

        <Row className="mt-4">
          <Col>
            <Image src={Image01} fluid={true} className="d-block" />
          </Col>
          <Col>
            <Gallery images={data.allFile.edges} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Koenigsschiessen2012
